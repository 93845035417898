"use client";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export default function DemoImages({ images }) {
  return (
    <div className="demo-images-wrapper">
      <div className="large-square">
        <h2>Kodiak outlasts the competition</h2>
        <h4>Get a free on site demo</h4>
        <Link href="/demo">Schedule a Demo</Link>
      </div>
      <div className="four-swatches">
        {images.slice(0, 4).map((image, index) => (
          <div key={index} className="demo-image-wrapper">
            <Image
              src={image.fileLink}
              alt={image.altText}
              height={1080}
              width={1920}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
