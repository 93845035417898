"use client";
import Image from "next/image";
import { useRouter } from "next/navigation";

export const NavMachineCard = ({ machine }) => {
  const router = useRouter();

  const handleClick = (machine) => {
    if (machine.attributes.isFloorMachine) {
      router.push("/models/floor-machines");
    } else {
      router.push(`/models/${machine.attributes.slug}`);
    }
  };

  return (
    <>
      <div className="model-card" onClick={() => handleClick(machine)}>
        <Image
          src={machine.attributes.main_image.data.attributes.url}
          width={1920}
          height={1080}
          alt="floor machines"
        />
        {machine.attributes.isFloorMachine ? (
          <h4>Floor Machines</h4>
        ) : (
          <h4>{machine.attributes.name} Series</h4>
        )}
      </div>
    </>
  );
};
